import { getApi, postApi, putApi } from "../api";

// export const createNewClient = (data) => getApi(`login`, data);

export const createNewClient = (data) => postApi(`login`, data);

// export const editClientConatctData = (data) => {
//   const { id, contact } = data;
//   return putApi(`Client/contact?clientId=${id}`, contact);
// };
