import React, { createContext, useContext, useState } from "react";
import { createNewClient } from "../../controllers/auth";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { setStorageValue } from "../../Cookies";

const authContext = createContext();
export const useAuth = () => useContext(authContext);
function useAuthProvider() {
  const navigate = useNavigate();
  const [hello, setHello] = useState("sadasdasdas");

  // const HandleLogin = (data) =>
  //   useQuery(["AllclientName"], () => createNewClient(data), {
  //     enabled: true,
  //     select: (data) => data?.data,
  //     onSuccess: (data) => {
  //       console.log("====================================");
  //       console.log(data, "adsdasdasd");
  //       console.log("===================================="); // TokenExpiry(data?.ResponseCode);
  //     },
  //     cacheTime: 0,
  //   });
  const { mutate: mutateCreateClient, isLoading: isCreateClientLoading } =
    useMutation(createNewClient, {
      onSuccess: (data) => {
        // setCreateClientId(data?.data?.result?.clientID);
        // queryClient.refetchQueries("FilteredclientList");
        // queryClient.refetchQueries("AllclientName");
        // if (isTokenExpired(data?.data?.ResponseCode)) {
        //   TokenExpiry(data?.data?.ResponseCode);
        // } else
        if (data?.data.success) {
          toast.success("Login succsessfull");
          setStorageValue("access_token", data.data.token);
          navigate("/AdminPanel");

          //   setNoDublicateEmailsFound(false);
        } else {
          toast.error("Credentials are wrong");
        }
      },
      onError: (err) => {
        console.log(err);
      },
    });

  const HandleLogin = (data) => {
    mutateCreateClient(data);
  };

  return { hello, setHello, HandleLogin };
}

export function ProvideAuth({ children }) {
  const auth = useAuthProvider();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}
