import React from "react";
import Logo from "../../assets/Logo_png.png";
import SolutionStep from "././SolutionStep";
import "./Styles/About.css";

function About() {
  return (
    <div className="about-section" id="about">
      <div className="about-image-content">
      <h3 className="about-title">
          <span>About Us</span>
        </h3>
        <img src={Logo} alt="Logo" className="about-image1" />
      </div>

      <div className="about-text-content">
       
        <p className="about-description">
        Welcome to SN Real Estate, your trusted partner for accessible and personalized real estate services. 
        Our team of experienced agents is dedicated to providing expert guidance,
        ensuring your property needs are met with care and precision. 
        Embark on this journey towards finding your ideal property.
        </p>

        <h4 className="about-text-title">Your Solutions</h4>

        <SolutionStep
          title="Discover Ideal Properties"
          description="Explore a curated selection of properties with Realty Hub. Our expert agents prioritize your preferences, offering personalized guidance for finding the perfect home or investment property."
        />

        <SolutionStep
          title="Schedule Property Viewings"
          description="Choose convenient dates and times to view your desired properties. Our dedicated team ensures a smooth and personalized viewing experience, helping you make informed decisions."
        />

        <SolutionStep
          title="Secure Your Dream Property"
          description="Our experienced real estate professionals are ready to offer expert advice and personalized assistance in securing your dream property. Let us guide you through the buying or renting process, ensuring a seamless transaction."
        />
        <SolutionStep
  title="Diverse Property Options"
  description="Explore a wide range of properties with Realty Hub, including flats, penthouses, offices, shops, RCC/tiled houses, house sites, hostels, godowns, agricultural land, beach sites, factories, commercial spaces, paying guest accommodations, farmhouses, and more. Whatever your needs, we have the perfect property for you."
/>

      </div>

    </div>
  );
}

export default About;
