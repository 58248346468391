import React, { useState } from "react";
import { FaPhone, FaEnvelope, FaWhatsapp } from "react-icons/fa";
import { toast } from "react-toastify";
import Pdp from "../../components/ClientHome/Pdp";
import { useParams } from "react-router-dom";
import { useDashboard } from "../../utils/hooks";

function Landcategory() {
  //   const [amount, setAmount] = useState(1);
  const { Productobj } = useDashboard();

  const { id } = useParams();

  return <>{id && <Pdp id={id} object={Productobj} />}</>;
}

export default Landcategory;
