import React from "react";
import Navbar from "../../components/ClientHome/Navbar";
import Product from "../../components/ClientHome/Product";

function Viewall() {
  return (
    <>
      <Navbar />
      <Product />
    </>
  );
}

export default Viewall;
