import React from "react";
import Navbar from "../../components/ClientHome/Navbar";
import Footer from "../../components/ClientHome/Footer";
import Banner from "../../components/ClientHome/Banner";
import Product from "../../components/ClientHome/Product";
import About from "../../components/ClientHome/About";

function Home() {
  return (
    <>
      <Navbar />
      <Banner />
      <Product />
      <About />
      <Footer />
    </>
  );
}

export default Home;
