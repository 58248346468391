import React, { useState } from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBRipple,
} from "mdb-react-ui-kit";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./Styles/Products.css";
import { useNavigate, useLocation } from "react-router-dom";
import { useDashboard } from "../../utils/hooks";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";

function Product() {
  const { ProductobjTop10, Productobj } = useDashboard();
  const [inputText, setInputText] = useState("");
  const location = useLocation();
  const routeName = location.pathname;

  const routeCategory = routeName.replace(/^.*\//, "");

  const navigate = useNavigate();
  const handleInputTimeChange = (event) => {
    setInputText(event.target.value);
  };

  const filterProductsByTime = (product) => {
    const routeCategoryLowerCase = routeCategory.toLowerCase();
    const productNameLowerCase = product.property.toLowerCase();
    const inputTextLowerCase = inputText.toLowerCase();

    const categoryMatch =
      routeName === "/ViewAll" ||
      product.category.toLowerCase().trim() === routeCategoryLowerCase;

    // Check if product's name includes the input text
    const textMatch =
      inputTextLowerCase === "" ||
      productNameLowerCase.includes(inputTextLowerCase);

    // const isActiveMatch = product.isActive;
    const isActiveMatch = product.isActive === true;

    return categoryMatch && textMatch && isActiveMatch;
  };

  return (
    <div className="info-section" id="services">
      <div className="info-title-content">
        <div className="info-title">
          <span className="info-title-all">Properties</span>
          {routeName === "/ViewAll" && (
            <Box
              component="form"
              sx={{
                width: "25ch",
                marginRight: "30px",
              }}
              noValidate
              autoComplete="off"
            >
              <TextField
                id="outlined-controlled"
                label="Search"
                variant="outlined"
                value={inputText}
                onChange={handleInputTimeChange}
                className="  text-sm  text-black"
              />
            </Box>
          )}
          {routeName === "/ViewAll" ||
            (routeName !== "/" && (
              <h4
                className="info-title-viewall"
                onClick={() => {
                  navigate("/ViewAll");
                }}
              >
                View All
              </h4>
            ))}
        </div>
        <MDBContainer fluid className="my-5 text-center">
          <MDBRow>
            {(routeName === "/"
              ? ProductobjTop10
              : Productobj?.filter(filterProductsByTime)
            ).length > 0 ? (
              (routeName === "/"
                ? ProductobjTop10
                : Productobj?.filter(filterProductsByTime)
              ).map((product, index) => (
                <MDBCol
                  key={index}
                  md="4"
                  lg="4"
                  sm={6}
                  xs={6}
                  className="mb-4 "
                  onClick={() => {
                    navigate(`/land/${product.id}`);
                  }}
                >
                  <MDBCard>
                    <MDBRipple
                      rippleColor="light"
                      rippleTag="div"
                      className="bg-image rounded hover-zoom"
                    >
                      <MDBCardImage
                        src={product.imagess[0]}
                        fluid
                        className=" max-h-[270px] w-[100%] object-cover"
                      />
                      {/* ... Rest of the card content remains the same */}
                    </MDBRipple>
                    <MDBCardBody>
                      <div className="text-reset">
                        <h5 className="card-title mb-3">{product.name}</h5>
                      </div>
                      <div className="text-reset">
                        <p>{product.property}</p>
                      </div>
                      <div className="text-reset">
                        <h2>{product.price}</h2>
                      </div>
                    </MDBCardBody>
                  </MDBCard>
                </MDBCol>
              ))
            ) : (
              <div className="no-data-message">Sorry, no data available.</div>
            )}
          </MDBRow>
        </MDBContainer>
        {routeName === "/" && (
          <h4
            className="info-title-viewall"
            onClick={() => {
              navigate("/ViewAll");
            }}
          >
            View All
          </h4>
        )}
      </div>
    </div>
  );
}

export default Product;
