import { QueryClient, QueryClientProvider } from "react-query";
import "./App.css";
import RoutesContainer from "./router/RoutesContainer";

const twentyFourHoursInMs = 1000 * 60 * 60 * 24;
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: twentyFourHoursInMs,
    },
  },
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <RoutesContainer />
    </QueryClientProvider>
  );
}

export default App;
