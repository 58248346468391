import React from "react";
import "./Styles/Footer.css";
import { useNavigate } from "react-router-dom";
import Logo from "../../assets/Logo_png.png";
import { Link } from "react-router-dom";

function Footer() {
  const navigate = useNavigate();
  return (
    <div className="footer-section">
      <div className="footer-container">
        <div className="ft-info">
          <div className="ft-info-p1">
            <h1 className="footer-logo">
              <Link to="/">
                <img className="navbar-logo" src={Logo} alt="logo" />{" "}
              </Link>
            </h1>
            <p className="ft-description">
              Talk to us online and get to know more about us. Searching House
              and sites are easy now, everything on your fingertips.
            </p>
            <button
              className="text-appointment-btn"
              type="button"
              onClick={() => navigate("Login")}
            >
              Developer Access
            </button>
          </div>

          {/* <SubscribeNewsletter /> */}
        </div>

        <div className="ft-list">
          <p className="ft-list-title">Quick Links</p>
          <ul className="ft-list-items">
            <li>
              <Link to="/">Dashboard</Link>
            </li>

            <li>
              <Link to="/site/Land">Land</Link>
            </li>
            <li>
              <Link to="/site/Flats">Residential Flat</Link>
            </li>
            <li>
              <Link to="/site/Commercial">Commercial</Link>
            </li>
            <li>
              <Link to="/site/House">House</Link>
            </li>
            <li>
              <Link to="/site/Beach">Beach</Link>
            </li>
            <li>
              <Link to="/site/apartments">Apartments</Link>
            </li>
            {/* <li>
              <Link to="/about">About</Link>
            </li> */}
          </ul>
        </div>

        <div className="ft-list" id="contact">
          <p className="ft-list-title">Talk To Us</p>
          <ul className="ft-list-items">
            <li>
              <a href="mailto:Sndevelopersudupi@gmail.com">
                Sndevelopersudupi@gmail.com
              </a>
            </li>

            <li>
              <a href="tel:+91 6364730585">+91 6364730585</a>
            </li>
            <li>
              <a href="tel:+91 9008728123">+91 9008728123 </a>
            </li>
          </ul>
        </div>
      </div>

      <div className="ft-copyright">
        <p>© 2024 SN-. All rights reserved.</p>

        <ul className="ft-social-links">
          <li>
            <a
              href="https://www.facebook.com/profile.php?id=100063757657584&mibextid=ZbWKwL"
              title="FaceBook"
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 320 512"
              >
                <path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z" />
              </svg>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Footer;
