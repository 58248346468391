import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "../App.css";
import Home from "../pages/ClientDashboard/Home";
import LoginPage from "../pages/login/login";
import Pdp from "../pages/Land/land";
import ContextContainer from "../utils/context";
import Viewall from "../pages/ViewAll/Viewall";
import PagenotFound from "../components/ClientHome/PagenotFound";
import ViewallFiltered from "../pages/filterdSite";

function RoutesContainer() {
  // const [openSidebarToggle, setOpenSidebarToggle] = useState(false);

  // const OpenSidebar = () => {
  //   setOpenSidebarToggle(!openSidebarToggle);
  // };

  return (
    <BrowserRouter>
      <ContextContainer>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/site/:site" element={<ViewallFiltered />} />
          <Route path="/ViewAll" element={<Viewall />} />
          <Route path="/Login" element={<LoginPage />} />
          <Route path="/Land/:id" element={<Pdp />} />
          <Route path="*" element={<PagenotFound />} />

          {/* <Route
            path="/*"
            element={
              <div className="grid-container">
                <Header OpenSidebar={OpenSidebar} />
                <Sidebar
                  openSidebarToggle={openSidebarToggle}
                  OpenSidebar={OpenSidebar}
                />
                <Routes>
                  <Route
                    path="/AdminPanel"
                    element={
                      <PrivateRoute>
                        <Dashboard />
                      </PrivateRoute>
                    }
                  />

                  <Route
                    path="/site"
                    element={
                      <PrivateRoute>
                        <Sites />
                      </PrivateRoute>
                    }
                  />
                </Routes>
              </div>
            }
          /> */}
        </Routes>
      </ContextContainer>
    </BrowserRouter>
  );
}

export default RoutesContainer;
