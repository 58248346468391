

//   return (
//     <div className="Auth-form-container">
//       <form onSubmit={handleSubmit(onSubmit)} className="Auth-form">
//         <div className="Auth-form-content">
//           <img src="" alt="Logo" className="logo" />

//           <h3 className="Auth-form-title">Log In</h3>
//           <div className="form-group mt-3">
//             <label>Email</label>
//             <Controller
//               name={"email" || ""}
//               control={control}
//               rules={{
//                 required: "Email is Required",
//                 pattern: {
//                   value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
//                   message: "Enter AValid Email Address",
//                 },
//               }}
//               render={({ field }) => (
//                 <input
//                   type="email"
//                   className="form-control mt-1"
//                   placeholder="Enter email"
//                   {...field}
//                   label="Email"
//                   size="medium"
//                   // disabled={isRowDisabled(index)}
//                 />
//               )}
//             />
//           </div>
//           <div className="form-group mt-3 position-relative">
//             <label>Password</label>
//             <div className="input-group">
//               <Controller
//                 name={"password"}
//                 control={control}
//                 render={({ field }) => (
//                   <input
//                     type={showPassword ? "text" : "password"}
//                     className="form-control mt-1"
//                     {...field}
//                     placeholder="Enter password"
//                     label="Email"
//                     size="medium"
//                     // disabled={isRowDisabled(index)}
//                   />
//                 )}
//               />
//               <div className="input-group-append position-absolute top-0 end-0 mt-1 me-2">
//                 <button
//                   type="button"
//                   className="btn btn-outline"
//                   onClick={() => setShowPassword(!showPassword)}
//                 >
//                   <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
//                 </button>
//               </div>
//             </div>
//           </div>
//           <div className="d-grid gap-2 mt-3">
//             <button type="submit" className="btn btn-primary">
//               Submit
//             </button>
//           </div>
//           <p className="forgot-password text-right mt-2">
//             <a href="#"> Forgot password?</a>
//           </p>
//         </div>
//       </form>
//     </div>
//   );
// }

// export default LoginPage;
import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";
import "./login.css";
import { useAuth } from "../../utils/hooks";
import { useForm, Controller } from "react-hook-form";
import Banner from "../../assets/banner1.jpg";

function LoginPage() {
  const [showPassword, setShowPassword] = useState(false);
  const { HandleLogin } = useAuth();
  const { handleSubmit, control } = useForm();

  const onSubmit = (data) => {
    HandleLogin(data);
  };

  return (
    <>
      <div className="container">
        <div className="left">
          <form onSubmit={handleSubmit(onSubmit)} className="login-form">
            <h2>Login</h2>
            <div class="from-div">
              <label>Email</label>
              <Controller
                name={"email" || ""}
                control={control}
                rules={{
                  required: "Email is Required",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Enter AValid Email Address",
                  },
                }}
                render={({ field }) => (
                  <input
                    type="email"
                    placeholder="Enter email"
                    {...field}
                    label="Email"
                    size="medium"
                  />
                )}
              />
              <label>Password</label>
              <Controller
                name={"password"}
                control={control}
                render={({ field }) => (
                  <input
                    type={showPassword ? "text" : "password"}
                    {...field}
                    placeholder="Enter password"
                    label="Email"
                    size="medium"
                  />
                )}
              />
            </div>
            <button type="submit">Login</button>
            <p className="forgot-password">
              <a href="#">Forgot Password?</a>
            </p>
          </form>
        </div>

        <div className="right">
          <img src={Banner} alt="Login Image" />
        </div>
      </div>
    </>
  );
}

export default LoginPage;
