import Cookies from "universal-cookie";
const cookies = new Cookies();

export const setStorageValue = (key, value) => {
  const now = new Date();
  const time = now.getTime();
  const expireTime = time + 24 * 60 * 60 * 1000; // 24 hours expiration time
  // const expireTime = time + 10 * 60 * 1000; // 1 min expiration time
  now.setTime(expireTime);

  // Set the cookie
  document.cookie = `${key}=${value}; path=/;cookie=ok; Expires=${now.toUTCString()};`;
  // cookies.set(key, value, { expires: now });

  return value;
};

export const getStorageValue = (key) => {
  const value = cookies.get(key);
  return value;
};

export const clearData = () => {
  localStorage.removeItem("responseCode");
  localStorage.removeItem("forceResetResponseCode");
  localStorage.removeItem("user-details");
  sessionStorage.removeItem("authToken");
  document.cookie =
    "access_token=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;";
  // cookies.remove("access_token", { path: "/" });
  window.location.reload();
};
