import React, { useState } from "react";
import { FaPhone, FaEnvelope, FaWhatsapp, FaRupeeSign } from "react-icons/fa";
import { toast } from "react-toastify";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";

function Pdp({ id, object }) {
  const navigate = useNavigate();

  const { name, property, description, imagess, price } = object[id - 1];

  const handleWhatsappClick = () => {
    const whatsappUrl = "https://wa.me/6364730585";
    toast.info("Redirecting to WhatsApp...");
    window.open(whatsappUrl);
  };

  const handleEmailClick = () => {
    window.location.href = "mailto:Sndevelopersudupi@gmail.com";
  };

  const [activeImg, setActiveImage] = useState(imagess[0]);

  return (
    <Grid container spacing={2} style={{ maxHeight: "90vh" }}>
      <Grid item lg={6} md={6} sm={12} xs={12}>
        <div className="relative" style={{ maxHeight: "90vh" }}>
          <button
            className="fixed top-4 left-4 z-10 bg-black bg-opacity-25 backdrop-filter backdrop-blur-md text-white p-2 rounded"
            onClick={() => {
              navigate(`/`);
            }}
          >
            Back
          </button>
          <Carousel
            showArrows={true}
            showThumbs={false}
            infiniteLoop={true}
            autoPlay={true}
            interval={6000}
            stopOnHover={true}
            swipeable={true}
            emulateTouch={true}
            dynamicHeight={false}
          >
            {imagess.map((imga, index) => (
              <img
                key={index}
                src={imga}
                alt=""
                className="w-full h-auto p-2"
                style={{ maxHeight: "100vh" }}
              />
            ))}
          </Carousel>
        </div>
      </Grid>
      <Grid item lg={6} md={6} sm={12} xs={12}>
        <div className="flex flex-col gap-4  ml-4 lg:mt-20">
          <div>
            <span className="text-violet-600 font-semibold">{name}</span>
            <h1 className="text-3xl font-bold">{property}</h1>
            <div className="flex items-center gap-2">
              <FaRupeeSign className="text-xl text-green-500" />
              <h1 className="text-xl font-bold mt-1">{price}</h1>
            </div>
          </div>
          <p className="text-gray-700">{description}</p>
          <div className="border p-4 rounded-lg bg-gray-200 mb-2 mr-2 ">
            <h2 className="text-lg font-semibold mb-2">
              Interested? Contact us
            </h2>
            <div className="flex flex-col items-start gap-2">
              <p
                className="text-sm flex items-center cursor-pointer text-coolred"
                onClick={() => {
                  window.location.href = "tel:+6364730585";
                }}
              >
                <FaPhone className="mr-2 h-6 w-6" /> Phone: 6364730585
              </p>
              <p
                className="text-sm flex items-center cursor-pointer text-coolred"
                onClick={() => {
                  window.location.href = "tel:+9008728123";
                }}
              >
                <FaPhone className="mr-2 h-6 w-6" /> Phone: 9008728123
              </p>
              <p
                className="text-sm flex items-center cursor-pointer text-coolred"
                onClick={handleEmailClick}
              >
                <FaEnvelope className="mr-2 h-6 w-6" /> Email:
                Sndevelopersudupi@gmail.com
              </p>
              <p
                className="text-sm flex items-center cursor-pointer text-coolred"
                onClick={handleWhatsappClick}
              >
                <FaWhatsapp className="mr-2 h-6 w-6" /> WhatsApp: 6364730585
              </p>
            </div>
          </div>
        </div>
      </Grid>
    </Grid>
  );
}

export default Pdp;
