import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCommentDots,
  faBars,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import "./Styles/Navbar.css";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Logo from "../../assets/Logo_png.png";

function Navbar() {
  const [nav, setNav] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);


  const openNav = () => {
    setNav(!nav);
  };

  const handleChatBtnClick = () => {
    if (!isButtonDisabled) {
      const whatsappUrl = "https://wa.me/6364730585";
      toast.info("Redirecting to WhatsApp...");
      setIsButtonDisabled(true);
      window.open(whatsappUrl);
    }
  };

  const handleLinkClick = () => {
    if (nav) {
      setNav(false);
    }
  };

  return (
    <div className="navbar-section">
      <h1 className="navbar-logo">
        <Link to="/">
          <img className="navbar-logo" src={Logo} alt="logo" />{" "}
        </Link>
      </h1>

      {/* Desktop */}
      <ul className="navbar-items">
        <li>
          <Link to="/" className="navbar-links" onClick={handleLinkClick}>
            Home
          </Link>
        </li>
        {/* <li>
          <Link to="about" className="navbar-links" onClick={handleLinkClick}>
            About
          </Link>
        </li> */}
        <li>
          <Link
            to="/site/apartments"
            className="navbar-links"
            onClick={handleLinkClick}
          >
            Apartments
          </Link>
        </li>
        <li>
          <Link
            to="/site/Flats"
            className="navbar-links"
            onClick={handleLinkClick}
          >
            Residential Flats
          </Link>
        </li>
        <li>
          <Link
            to="/site/Commercial"
            className="navbar-links"
            onClick={handleLinkClick}
          >
            Commercial
          </Link>
        </li>
        <li>
          <Link
            to="/site/House"
            className="navbar-links"
            onClick={handleLinkClick}
          >
            House
          </Link>
        </li>
        <li>
          <Link
            to="/site/Beach"
            className="navbar-links"
            onClick={handleLinkClick}
          >
            Beach
          </Link>
        </li>
        <li>
          <Link
            to="/site/Land"
            className="navbar-links"
            onClick={handleLinkClick}
          >
            Land
          </Link>
        </li>
      </ul>

      <button
        className="navbar-btn"
        type="button"
        disabled={isButtonDisabled}
        onClick={handleChatBtnClick}
      >
        <FontAwesomeIcon icon={faCommentDots} /> Chat
      </button>

      {/* Mobile */}
      <div className={`mobile-navbar ${nav ? "open-nav" : ""}`}>
        <div onClick={openNav} className="mobile-navbar-close">
          <FontAwesomeIcon icon={faXmark} className="hamb-icon" />
        </div>

        <ul className="mobile-navbar-links">
          <li>
            <Link to="/" onClick={handleLinkClick}>
              Home
            </Link>
          </li>
          <li>
            <Link to="/#about" onClick={handleLinkClick}>
              About
            </Link>
          </li>
          <li>
            <Link to="/site/apartments" onClick={handleLinkClick}>
              Apartments
            </Link>
          </li>
          <li>
            <Link to="/site/Flats" onClick={handleLinkClick}>
              Residential Flat
            </Link>
          </li>
          <li>
            <Link to="/site/Commercial" onClick={handleLinkClick}>
              Commercial
            </Link>
          </li>
          <li>
            <Link to="/site/House" onClick={handleLinkClick}>
              House
            </Link>
          </li>
          <li>
            <Link to="/site/Beach" onClick={handleLinkClick}>
              Beach
            </Link>
          </li>
          <li>
            <Link
              to="/site/Land"
              className="navbar-links"
              onClick={handleLinkClick}
            >
              Land
            </Link>
          </li>
          <li>
            <button onClick={handleChatBtnClick}>
              <FontAwesomeIcon icon={faCommentDots} /> Chat
            </button>
          </li>
        </ul>
      </div>

      {/* Hamburger Icon */}
      <div className="mobile-nav">
        <FontAwesomeIcon
          icon={faBars}
          onClick={openNav}
          className="hamb-icon"
        />
      </div>
    </div>
  );
}

export default Navbar;
