import React, { createContext, useContext } from "react";
const authContext = createContext();
export const useDashboard = () => useContext(authContext);

function useDashboardProvider() {
  const ProductobjTop10 = [
    {
      id: 1,
      name: "Shirva",
      category: "Land ",
      isActive: true,
      imagess: [
        " https://lh3.googleusercontent.com/d/1WMeAWQJrAI2lQrbGjdzWmGzD8ykxKQhr=w1000",
        " https://lh3.googleusercontent.com/d/1CkfpZRlSEwqo6EFQzdAqMaBcdnuRALSD=w1000",
        " https://lh3.googleusercontent.com/d/1Nf1tSJnotDXW67gBPu4Dy_ZKRjz-y_zO=w1000",
        " https://lh3.googleusercontent.com/d/1VtiXDPZdUBBjovunHZhfYSgrWc2vZzJN=w1000",
        " https://lh3.googleusercontent.com/d/1GJFU_pmUigGWibUkLuyVpjIsxYp15T4R=w1000",
      ],
      property: "1) 29 acres agriculture land",
      price: "7.5 CR",
      description: ` Discover your opportunity to own a piece of lush agricultural land, rich with vibrant arecanut and coconut tree plantations along with rubber tree. , its a river touch property. This property is currently generating 60lakhs income per annum`,
    },
    {
      id: 2,
      name: "shastri circle kundapura",
      category: "Land",
      isActive: true,
      imagess: [
        " https://lh3.googleusercontent.com/d/1Jq6xf3dxFGmS3rP3550MgkLSBIW4uhiD=w1000",
        " https://lh3.googleusercontent.com/d/1sItI4cpClsDQrDpT5xi_q6XahFEQ6E9b=w1000",
        " https://lh3.googleusercontent.com/d/1nkylUtS7auIqdW7kYDv7Fwrr03hB_XoD=w1000",
        " https://lh3.googleusercontent.com/d/1m-l5AhjZNjpuniKVzf-pozPWhn0vE4P9=w1000",
      ],
      property: "2) 13 acre River touch property",
      price: "2,00,000 per cents",
      description: ` Discover your opportunity to invest in 13 acres river touch property, which is just 1km from national highway, it has industrial conversion`,
    },
    {
      id: 3,
      name: "Kundapura",
      category: "Beach",
      isActive: true,
      imagess: [
        " https://lh3.googleusercontent.com/d/1kKiDtuPggpwgyNia7CjRvhNS_97Vjgsl=w1000",
        " https://lh3.googleusercontent.com/d/1tHHB3ZMEuoQCAItMTEHoPN78Tn3A0XhR=w1000",
        " https://lh3.googleusercontent.com/d/1a0hfAvDhVmUYVm31hxwQravqRaD-049Q=w1000",
      ],
      property: "3) 6.3 acres open beach property",
      price: "3,50,000 per cent",
      description: `This Beach property is located just 2.5 km from national highway. It has 6 door numbers and is suitable for farmhouse and resorts `,
    },
    {
      id: 4,
      name: "Kinnimulky",
      category: "House",
      isActive: true,
      imagess: [
        " https://lh3.googleusercontent.com/d/1ecBYlSe_uphEDgzoImB4-on4Xx2XI-_Q=w1000",
        " https://lh3.googleusercontent.com/d/1zfOpXGJ8KX1hFmhx-6xJBvroGwMSqfEH=w1000",
        " https://lh3.googleusercontent.com/d/19L7rJrG4gLMml7PpsZTZAyoH-ZOcGHR8=w1000",
        " https://lh3.googleusercontent.com/d/1N3dU5Dn8D6V6jtadwOyT5eD7JpLN3juE=w1000",
        " https://lh3.googleusercontent.com/d/1EWP-4LGG4C1xig2HKSzzgwz7XiclnR0H=w1000",
      ],
      property: "4) 5 Bhk house ",
      price: "3 CR",
      description: `A lavishing 5 BHK house with 53 cents land avaiable in kannarpady, kinnimulky, the house is more than 3000+ sqft plus it has 2 different labour rooms along with open well. This property is just 1 km from national highway`,
    },
    {
      id: 5,
      name: "Nakre",
      category: "Land",
      isActive: true,
      imagess: [
        " https://lh3.googleusercontent.com/d/1N2PJ4fe0vIJ4R4YCIHUMQ7Kd1r4ZKdLf=w1000",
        " https://lh3.googleusercontent.com/d/17ABYcB5oBX5lyIx8qAff5KiHANPekMSS=w1000",
        " https://lh3.googleusercontent.com/d/1P2ttTX5UJzBs5_PoF7wjBU3lDBKoNd1F=w1000",
        " https://lh3.googleusercontent.com/d/1CdZPmA5UO2peuvVKeVXznEputr3J2_Sb=w1000",
        " https://lh3.googleusercontent.com/d/1UOkFhDFaytUYBKWZPjLge2WNvHES8kcp=w1000",
        " https://lh3.googleusercontent.com/d/1TyQ3EC9JRotkJ3iNFSXrzhr713sUFAOh=w1000",
        " https://lh3.googleusercontent.com/d/1ZUfZ092Jnkh6X-sx2Aor7T_Pj6ZQ1LZ_=w1000",
        " https://lh3.googleusercontent.com/d/14tuGSktzJ7fjg_6JPh1Q22R-UCJOEfYU=w1000",
      ],
      property: "5) 13 acres Agriculture land ",
      price: "20000 per cents",
      description: `13 acres land along with 8-10 acre kumki is avaiable for sale. This plot has 3 open well, 2 residential house and is connected to concreate road. Its a river touch property and its just 3.3 km from nakre church and is suitable for any agriculture purpose, resort,farmhouse etc`,
    },
    {
      id: 6,
      name: "Ajekar",
      category: "Land",
      isActive: true,
      imagess: [
        " https://lh3.googleusercontent.com/d/1ABSoevihP1b9jE1XvQAsnGeXOf5b3Aiw=w1000",
        " https://lh3.googleusercontent.com/d/1zRf4D3m7_6uc29Imm5FW1iwcq_ui-5V6=w1000",
        " https://lh3.googleusercontent.com/d/1efGzrD1Flq676vMeAL1k4W-hNiEfJmg2=w1000",
        " https://lh3.googleusercontent.com/d/1qCflUsjJJtVkDiDCaZOy3NUmlPSUOJ2O=w1000",
        " https://lh3.googleusercontent.com/d/1rhOWe38i9JHd575SRtUyOtKTF4Ucr56i=w1000",
      ],
      property: "6) 18.5 acres agriculture land ",
      price: "3 CR",
      description: `18.5 acres agriculture land along with 3 acre kumki is avaiable for sale. This plot has 3 open well, 1 residential house and has 2000 arecanut trees, 100 coconut trees and 1000 rubber trees. of the total land , 4 acre is empty and can be utilized for any purpose`,
    },
    {
      id: 7,
      name: "Mulki",
      category: "Land",
      isActive: true,
      imagess: [
        " https://lh3.googleusercontent.com/d/1ehPhW7m0aiUVSkgJ6w46xG8rfhjF2TCa=w1000",
        " https://lh3.googleusercontent.com/d/1qM8OUJ2kZ9A45b8LMEXernil--uqB8yR=w1000",
        " https://lh3.googleusercontent.com/d/1EesxuUCQuAf8fQI4YxL2_KlNCbD610wU=w1000",
        " https://lh3.googleusercontent.com/d/1XjuYllEmy4q2F7V7AoD5CJnO_Yj7iDJt=w1000",
        " https://lh3.googleusercontent.com/d/1GA-cGS4R_HaIKWP6DT2BNuMtSapClzhq=w1000",
      ],
      property: "7) 53 acres agriculture land ",
      price: "25 CR",
      description: `53 acres agriculture land . This property has 1 open well, It is just 5.5km from mulki, near Ideal factory. This site is highly suitable for Indsutrial purpose`,
    },
    {
      id: 8,
      name: "Karkala",
      category: "Land",
      isActive: true,
      imagess: [
        " https://lh3.googleusercontent.com/d/1pG_qv-I_iZS2S7BcePEFqcwE48r2aTJD=w1000",
        " https://lh3.googleusercontent.com/d/1AdOo83iaXn_nQEkf3z9WQBnyi8v6sVs8=w1000",
        " https://lh3.googleusercontent.com/d/1qgBXmCklEOehtr3gvbU74ZGVX-q0v0k2=w1000",
        " https://lh3.googleusercontent.com/d/1bstiWb2lKLlTicS51X-gFkJo-tlrvci-=w1000",
      ],
      property: "8) 1.82 acres land",
      price: "6,00,000 per cents",
      description: ` Discover your opportunity to invest in 1.82 acre property, which is just 150m from karkala main bus stand,opposite of government hospital. This property is highly suitable for buildings and malls `,
    },
    {
      id: 9,
      name: "Byndoor",
      category: "Beach",
      isActive: true,
      imagess: [
        " https://lh3.googleusercontent.com/d/1FH0hbpg3Gka-mU3GFGqYveS3ZOWLW14y=w1000",
        " https://lh3.googleusercontent.com/d/10YxP25OmqyMmt5EwFcdNlY1kO_OYvxYP=w1000",
        " https://lh3.googleusercontent.com/d/1Z1t4sO0fC7u9xC3hZYusrZauT2hiMsq_=w1000",
        " https://lh3.googleusercontent.com/d/1VpTCVJat6RGts-5_JyCN0HYYQ2Ko1Bw6=w1000",
        " https://lh3.googleusercontent.com/d/1mF_ZcFXToLQmsvfZ_FipcD7dIWaHSNgJ=w1000",
        " https://lh3.googleusercontent.com/d/1JjDKekASnat6nI14VLwiRF0mbXAhQDAS=w1000",
      ],
      property: "9)1.43 acre Beach Property ",
      price: "2 lakhs per cent",
      description: `1.34 acre open beach property . This property has 2 door numbers, It is just 1.5km from national highway, . It is highly suitable for Resorts, Farmhouses`,
    },
    {
      id: 10,
      name: "Kota",
      category: "Beach ",
      isActive: true,
      imagess: [
        " https://lh3.googleusercontent.com/d/10FckHWFW9NTON8ntkCVrENkHG-7BDw57=w1000",
        " https://lh3.googleusercontent.com/d/1riK6UhCxzYZtPqvUZ_MkuZFH9JDNoFf8=w1000",
        " https://lh3.googleusercontent.com/d/1pexf66XxKnSk7MihBjuNHCpPYS71jCTU=w1000",
        " https://lh3.googleusercontent.com/d/1jZmlma28QtWAWR2GOJ2GR_2pSUKxuey9=w1000",
      ],
      property: "10) 53 cent open beach property",
      price: "4,50,000 per cent",
      description: `This 53 cents Beach property is located just 2.5 km from national highway near Tekkatte,Kota.There is additional 40 cents of kumki. It has 3 door numbers and is suitable for farmhouse and resorts `,
    },
  ];

  const Productobj = [
    {
      id: 1,
      name: "Shirva",
      category: "Land",
      isActive: true,
      imagess: [
        " https://lh3.googleusercontent.com/d/1WMeAWQJrAI2lQrbGjdzWmGzD8ykxKQhr=w1000",
        " https://lh3.googleusercontent.com/d/1CkfpZRlSEwqo6EFQzdAqMaBcdnuRALSD=w1000",
        " https://lh3.googleusercontent.com/d/1Nf1tSJnotDXW67gBPu4Dy_ZKRjz-y_zO=w1000",
        " https://lh3.googleusercontent.com/d/1VtiXDPZdUBBjovunHZhfYSgrWc2vZzJN=w1000",
        " https://lh3.googleusercontent.com/d/1GJFU_pmUigGWibUkLuyVpjIsxYp15T4R=w1000",
      ],
      property: "1) 29 acres agriculture land",
      price: "7.5 CR",
      description: ` Discover your opportunity to own a piece of lush agricultural land, rich with vibrant arecanut and coconut tree plantations along with rubber tree. , its a river touch property. This property is currently generating 60lakhs income per annum`,
    },
    {
      id: 2,
      name: "shastri circle kundapura",
      category: "Land",
      isActive: true,
      imagess: [
        " https://lh3.googleusercontent.com/d/1Jq6xf3dxFGmS3rP3550MgkLSBIW4uhiD=w1000",
        " https://lh3.googleusercontent.com/d/1sItI4cpClsDQrDpT5xi_q6XahFEQ6E9b=w1000",
        " https://lh3.googleusercontent.com/d/1nkylUtS7auIqdW7kYDv7Fwrr03hB_XoD=w1000",
        " https://lh3.googleusercontent.com/d/1m-l5AhjZNjpuniKVzf-pozPWhn0vE4P9=w1000",
      ],
      property: "2) 13 acre River touch property",
      price: "2,00,000 per cents",
      description: ` Discover your opportunity to invest in 13 acres river touch property, which is just 1km from national highway, it has industrial conversion`,
    },
    {
      id: 3,
      name: "Kundapura",
      category: "Beach ",
      isActive: true,
      imagess: [
        " https://lh3.googleusercontent.com/d/1kKiDtuPggpwgyNia7CjRvhNS_97Vjgsl=w1000",
        " https://lh3.googleusercontent.com/d/1tHHB3ZMEuoQCAItMTEHoPN78Tn3A0XhR=w1000",
        " https://lh3.googleusercontent.com/d/1a0hfAvDhVmUYVm31hxwQravqRaD-049Q=w1000",
      ],
      property: "3) 6.3 acres open beach property",
      price: "3,50,000 per cent",
      description: `This Beach property is located just 2.5 km from national highway. It has 6 door numbers and is suitable for farmhouse and resorts `,
    },
    {
      id: 4,
      name: "Kinnimulky",
      category: "House",
      isActive: true,
      imagess: [
        " https://lh3.googleusercontent.com/d/1ecBYlSe_uphEDgzoImB4-on4Xx2XI-_Q=w1000",
        " https://lh3.googleusercontent.com/d/1zfOpXGJ8KX1hFmhx-6xJBvroGwMSqfEH=w1000",
        " https://lh3.googleusercontent.com/d/19L7rJrG4gLMml7PpsZTZAyoH-ZOcGHR8=w1000",
        " https://lh3.googleusercontent.com/d/1N3dU5Dn8D6V6jtadwOyT5eD7JpLN3juE=w1000",
        " https://lh3.googleusercontent.com/d/1EWP-4LGG4C1xig2HKSzzgwz7XiclnR0H=w1000",
      ],
      property: "4) 5 Bhk house ",
      price: "3 CR",
      description: `A lavishing 5 BHK house with 53 cents land avaiable in kannarpady, kinnimulky, the house is more than 3000+ sqft plus it has 2 different labour rooms along with open well. This property is just 1 km from national highway`,
    },
    {
      id: 5,
      name: "Nakre",
      category: "Land",
      isActive: true,
      imagess: [
        " https://lh3.googleusercontent.com/d/1N2PJ4fe0vIJ4R4YCIHUMQ7Kd1r4ZKdLf=w1000",
        " https://lh3.googleusercontent.com/d/17ABYcB5oBX5lyIx8qAff5KiHANPekMSS=w1000",
        " https://lh3.googleusercontent.com/d/1P2ttTX5UJzBs5_PoF7wjBU3lDBKoNd1F=w1000",
        " https://lh3.googleusercontent.com/d/1CdZPmA5UO2peuvVKeVXznEputr3J2_Sb=w1000",
        " https://lh3.googleusercontent.com/d/1UOkFhDFaytUYBKWZPjLge2WNvHES8kcp=w1000",
        " https://lh3.googleusercontent.com/d/1TyQ3EC9JRotkJ3iNFSXrzhr713sUFAOh=w1000",
        " https://lh3.googleusercontent.com/d/1ZUfZ092Jnkh6X-sx2Aor7T_Pj6ZQ1LZ_=w1000",
        " https://lh3.googleusercontent.com/d/14tuGSktzJ7fjg_6JPh1Q22R-UCJOEfYU=w1000",
      ],
      property: "5) 13 acres Agriculture land ",
      price: "20000 per cents",
      description: `13 acres land along with 8-10 acre kumki is avaiable for sale. This plot has 3 open well, 2 residential house and is connected to concreate road. Its a river touch property and its just 3.3 km from nakre church and is suitable for any agriculture purpose, resort,farmhouse etc`,
    },
    {
      id: 6,
      name: "Mulki",
      category: "Land",
      isActive: false,
      imagess: [
        " https://lh3.googleusercontent.com/d/1ehPhW7m0aiUVSkgJ6w46xG8rfhjF2TCa=w1000",
        " https://lh3.googleusercontent.com/d/1qM8OUJ2kZ9A45b8LMEXernil--uqB8yR=w1000",
        " https://lh3.googleusercontent.com/d/1EesxuUCQuAf8fQI4YxL2_KlNCbD610wU=w1000",
        " https://lh3.googleusercontent.com/d/1XjuYllEmy4q2F7V7AoD5CJnO_Yj7iDJt=w1000",
        " https://lh3.googleusercontent.com/d/1GA-cGS4R_HaIKWP6DT2BNuMtSapClzhq=w1000",
      ],
      property: "6) 53 acres agriculture land ",
      price: "25 CR",
      description: `53 acres agriculture land . This property has 1 open well, It is just 5.5km from mulki, near Ideal factory. This site is highly suitable for Indsutrial purpose`,
    },
    {
      id: 7,
      name: "Mulki",
      category: "Land",
      isActive: true,
      imagess: [
        " https://lh3.googleusercontent.com/d/1ehPhW7m0aiUVSkgJ6w46xG8rfhjF2TCa=w1000",
        " https://lh3.googleusercontent.com/d/1qM8OUJ2kZ9A45b8LMEXernil--uqB8yR=w1000",
        " https://lh3.googleusercontent.com/d/1EesxuUCQuAf8fQI4YxL2_KlNCbD610wU=w1000",
        " https://lh3.googleusercontent.com/d/1XjuYllEmy4q2F7V7AoD5CJnO_Yj7iDJt=w1000",
        " https://lh3.googleusercontent.com/d/1GA-cGS4R_HaIKWP6DT2BNuMtSapClzhq=w1000",
      ],
      property: "7) 53 acres agriculture land ",
      price: "25 CR",
      description: `53 acres agriculture land . This property has 1 open well, It is just 5.5km from mulki, near Ideal factory. This site is highly suitable for Indsutrial purpose`,
    },
    {
      id: 8,
      name: "Karkala",
      category: "Land",
      isActive: true,
      imagess: [
        " https://lh3.googleusercontent.com/d/1pG_qv-I_iZS2S7BcePEFqcwE48r2aTJD=w1000",
        " https://lh3.googleusercontent.com/d/1AdOo83iaXn_nQEkf3z9WQBnyi8v6sVs8=w1000",
        " https://lh3.googleusercontent.com/d/1qgBXmCklEOehtr3gvbU74ZGVX-q0v0k2=w1000",
        " https://lh3.googleusercontent.com/d/1bstiWb2lKLlTicS51X-gFkJo-tlrvci-=w1000",
      ],
      property: "8) 1.82 acres land",
      price: "6,00,000 per cents",
      description: ` Discover your opportunity to invest in 1.82 acre property, which is just 150m from karkala main bus stand,opposite of government hospital. This property is highly suitable for buildings and malls `,
    },
    {
      id: 9,
      name: "Byndoor",
      category: "Beach",
      isActive: true,
      imagess: [
        " https://lh3.googleusercontent.com/d/1FH0hbpg3Gka-mU3GFGqYveS3ZOWLW14y=w1000",
        " https://lh3.googleusercontent.com/d/10YxP25OmqyMmt5EwFcdNlY1kO_OYvxYP=w1000",
        " https://lh3.googleusercontent.com/d/1Z1t4sO0fC7u9xC3hZYusrZauT2hiMsq_=w1000",
        " https://lh3.googleusercontent.com/d/1VpTCVJat6RGts-5_JyCN0HYYQ2Ko1Bw6=w1000",
        " https://lh3.googleusercontent.com/d/1mF_ZcFXToLQmsvfZ_FipcD7dIWaHSNgJ=w1000",
        " https://lh3.googleusercontent.com/d/1JjDKekASnat6nI14VLwiRF0mbXAhQDAS=w1000",
      ],
      property: "9)1.43 acre Beach Property ",
      price: "2 lakhs per cent",
      description: `1.34 acre open beach property . This property has 2 door numbers, It is just 1.5km from national highway, . It is highly suitable for Resorts, Farmhouses`,
    },
    {
      id: 10,
      name: "Kota",
      category: "Beach ",
      isActive: true,
      imagess: [
        " https://lh3.googleusercontent.com/d/10FckHWFW9NTON8ntkCVrENkHG-7BDw57=w1000",
        " https://lh3.googleusercontent.com/d/1riK6UhCxzYZtPqvUZ_MkuZFH9JDNoFf8=w1000",
        " https://lh3.googleusercontent.com/d/1pexf66XxKnSk7MihBjuNHCpPYS71jCTU=w1000",
        " https://lh3.googleusercontent.com/d/1jZmlma28QtWAWR2GOJ2GR_2pSUKxuey9=w1000",
      ],
      property: "10) 53 cent open beach property",
      price: "4,50,000 per cent",
      description: `This 53 cents Beach property is located just 2.5 km from national highway near Tekkatte,Kota.There is additional 40 cents of kumki. It has 3 door numbers and is suitable for farmhouse and resorts `,
    },
    {
      id: 11,
      name: "Ambagilu",
      category: "Flats",
      isActive: true,
      imagess: [
        " https://lh3.googleusercontent.com/d/1eTPcXm31v8vgs05t3Ry6cXxXwK3a9ebc=w1000",
        " https://lh3.googleusercontent.com/d/17Ym2FrOwsKJlZrqAgYUrF7SklBhCH7IT=w1000",
        " https://lh3.googleusercontent.com/d/1Gdzvfa_VxBo8e613UMX24sURs25Zzrtq=w1000",
        " https://lh3.googleusercontent.com/d/1IxJeyxe65E8Ro8y06rQ8FDPUTKjI=w1000",
        " https://lh3.googleusercontent.com/d/149F7-0eh_oS03l2dD1lTlpE_2ta3LKZQ=w1000",
      ],
      property: "11) 2 BHK flat ",
      price: "25,00,000",
      description: `2 BHK 850 sqft residential flat available for sale near Ambagilu , opposite of silas international school.This property is just 100 m from national highway. Its in 2nd floor  `,
    },
    {
      id: 12,
      name: "Bramavara",
      category: "Land",
      isActive: true,
      imagess: [
        " https://lh3.googleusercontent.com/d/1vVKr0A1zANGPfrnp6rLGfagVVcke3qtG=w1000",
        " https://lh3.googleusercontent.com/d/1MBMuwCMDH5jzmcHxmE_LZyS7l9RdcDf2=w1000",
        " https://lh3.googleusercontent.com/d/1_FGBWqLlgpcXqFmYdKZxtP35DZvXLBj7=w1000",
        " https://lh3.googleusercontent.com/d/1hya524rMpex62ZP8DJ5xI9dmvbnOISb7=w1000",
        " https://lh3.googleusercontent.com/d/1Y4YCC4jniUO0rlGdbt73yegh-4sGusqT=w1000",
      ],
      property: "12) 12.77 acre land ",
      price: "1,20,000 per cent",
      description: `12.77 acre land.Out of which 8 acre is converted and 4.77 acre is agriculture. This site is 700 m from cherkady state highway and it is highly suitable for layouts or any indsutry purposes. `,
    },
    {
      id: 13,
      name: "Bramavara",
      category: "Flats",
      isActive: true,
      imagess: [
        " https://lh3.googleusercontent.com/d/1nhAhmYR59Dh0_bsdplXa_8kEkYHFUwBE=w1000",
        " https://lh3.googleusercontent.com/d/1-zvcFAc4eKvvTkHYxoLmU7GzL6iWr3af=w1000",
        " https://lh3.googleusercontent.com/d/1-xjgfm0cJ876NmrX0Qf5tb07ZMdRr7J8=w1000",
        " https://lh3.googleusercontent.com/d/1lkp8SsI_zRzQWBojyJ0N5XiUkVUNZHoy=w1000",
        " https://lh3.googleusercontent.com/d/1ItnPC4FNlYExzSmigsgSbRIbsLEMn635=w1000",
      ],
      property: "13) 2 BHK flat ",
      price: "35,00,000",
      description: `2 BHK 1170 sqft residential flat available for sale near Bramavara.Its in 1st floor, This flat is spacious and has a car parking facility. `,
    },
    {
      id: 14,
      name: "chikkmangaluru",
      category: "Land",
      isActive: true,
      imagess: [
        " https://lh3.googleusercontent.com/d/1Xp3i8xI2IIFPpKMp6xip3XxIcE2bm9dr=w1000",
        " https://lh3.googleusercontent.com/d/1y8zOgD2a8_Z9Tw1oYkgBw5SXRUHwROXm=w1000",
        " https://lh3.googleusercontent.com/d/1l7IuoYMcWTIr3ioInfoO7ImXt-NSuJFE=w1000",
        " https://lh3.googleusercontent.com/d/1xmBGmYwbnG9xF43SSqC1Sd6ov4d3WLK2=w1000",
        " https://lh3.googleusercontent.com/d/1aydYXZo2BfeB6-jprTA5p57C70WlHuDC=w1000",
      ],
      property: "14) 1700 acre land ",
      price: "220 CR",
      description: `1120 varga land and rest is coffee and tea estate.Its located in chikkamangaluru district.Its company owned property. For more Information call or message us in whatsapp on the below phone number. `,
    },
    {
      id: 15,
      name: "Beluvai",
      category: "Land ",
      isActive: true,
      imagess: [
        " https://lh3.googleusercontent.com/d/1CocXUmDG8lvPauE3gtQXzf_PkJYabBOf=w1000",
        " https://lh3.googleusercontent.com/d/1i89B566-FJmd3LeSMBKgMbrD_CX4ntg_=w1000",
        " https://lh3.googleusercontent.com/d/18pHYhGFssRByLlL81CcK7UiSx_ONHHCP=w1000",
        " https://lh3.googleusercontent.com/d/1btIXAY945twithul2dauhgO6-KZ_jWlH=w1000",
        " https://lh3.googleusercontent.com/d/1y081acugd76xJy9N-s8_a8udG--8V7iW=w1000",
      ],
      property: "15) 6.43 acre land",
      price: "50,000 per cent",
      description: `This 6.43 cents  property is located just 1 km from state highway near Beluvai . Behind this property a new college will be built soon. This property has one open well. This property is highly suitable for layouts  `,
    },
    {
      id: 16,
      name: "Shiroor",
      category: "Beach",
      isActive: true,
      imagess: [
        " https://lh3.googleusercontent.com/d/1qDuU16tZXQxjI5pBKMt0ap05Kb_TtAcN=w1000",
        " https://lh3.googleusercontent.com/d/1LiKHUaSe0X_-AWnln-u921X_Y0pCdjEK=w1000",
        " https://lh3.googleusercontent.com/d/1j4keiB3QQPYpqWzg9MqLYcTyPq0Hi3Cx=w1000",
        " https://lh3.googleusercontent.com/d/1FXLI8bnzxxx_lD6YD_6RA62-n4y0p9mB=w1000",
        " https://lh3.googleusercontent.com/d/1ZrFkQPzvdhQZSM8-oPhziCwOqVAVDMOC=w1000",
        " https://lh3.googleusercontent.com/d/1JCSnxepPtDk6ati_wmqkCzw3ozokxCg3=w1000",
        " https://lh3.googleusercontent.com/d/1FxHx_n7b_Juw_z7pFNRQok72QO_y_rCh=w1000",
      ],
      property: "16)8 acre open beach property ",
      price: "12.5 CR",
      description: `8 acre open beach property . It is highly suitable for Resorts, Farmhouses, Hotel , Toursism`,
    },
    {
      id: 17,
      name: "Ninjoor",
      category: "Land",
      isActive: true,
      imagess: [
        " https://lh3.googleusercontent.com/d/1CUtb2jUtu6Cs5L10K19FpSK7nrFkkn4r=w1000",
        " https://lh3.googleusercontent.com/d/1s4s2bq4xhJitdorL95mgL2DiY1Z1MsRu=w1000",
        " https://lh3.googleusercontent.com/d/1p3UFVC7rbG6UwItwPsLa1cAn-_GBI6ue=w1000",
        " https://lh3.googleusercontent.com/d/1H2GVzWNJ5WuCiWdRWzyeVd_ZEJOkDlgU=w1000",
      ],
      property: "17) 11.22 acres land",
      price: "15000 per cents",
      description: ` Discover your opportunity to invest in 11.22 acre property, which is just 1.5km from main road near ninjoor,palli .It has good source of water .This is property is highly suitable for agriculture and factory and also layouts `,
    },
    {
      id: 18,
      name: "karwar",
      category: "Land",
      isActive: true,
      imagess: [
        " https://lh3.googleusercontent.com/d/1jsoYVChPcI-WwUdUWUI27gCezAT2Sqz0=w1000",
        " https://lh3.googleusercontent.com/d/1inMg1V6VJ6SEoaY6pyoezJIhfiiTk0x7=w1000",
        " https://lh3.googleusercontent.com/d/1PJs5MPFoUr8L8hlm9P-TkDkD_Ml8xGUC=w1000",
      ],
      property: "18) 30 acres land",
      price: "3 CR",
      description: ` Discover your opportunity to invest in 30 acre property, which is just 15km from karwar .Its a river touch property.It has good source of water .This is property is highly suitable for agriculture and factory and also layouts `,
    },
    {
      id: 19,
      name: "Kuntalnagar",
      category: "House",
      isActive: true,
      imagess: [
        " https://lh3.googleusercontent.com/d/1U8sOurhr8bwblJZJ0TLi09NOSBOkhwqE=w1000",
        " https://lh3.googleusercontent.com/d/1XJGt_UDgDGD0JuehgDjW-wMDpn0FGQuh=w1000",
        " https://lh3.googleusercontent.com/d/13IOXCCtdxkaYgjMqMA9CwTq-fEGBzb81=w1000",
        " https://lh3.googleusercontent.com/d/13LcYGFe4FqNl_QLS9o44HrEnuU6JJizD=w1000",
        " https://lh3.googleusercontent.com/d/1DyFvsuyQgRpzLl6l5XbxzK_rnXhC6U_P=w1000",
      ],
      property: "19) 2 Bhk house ",
      price: "45,00,000",
      description: `A lavishing 2 BHK house with 11 cents land avaiable in kuntalnagara. This property has 11 cents land along with open well. It also has a extra undergroud basement room `,
    },
    {
      id: 20,
      name: "koteshwara",
      category: "Commercial",
      isActive: true,
      imagess: [
        " https://lh3.googleusercontent.com/d/1B2-dwQKkot9eXwQZtg7w1KlArkICMYWv=w1000",
        " https://lh3.googleusercontent.com/d/1Gm2_CaxFXo2Ls-fJJRDARV2dkjYyRjKi=w1000",
        " https://lh3.googleusercontent.com/d/1XwqSSjk1_mgapB-h-nAHGh3QEZu_UG3U=w1000",
        " https://lh3.googleusercontent.com/d/1SYyy5rU9z169ga4A0EHt_vN0u09vdUVF=w1000",
      ],
      property: "20) commercial building for sale",
      price: "45 CR",
      description: ` Discover your opportunity to invest in commercial building property, which is situated in national highway in koteswara .It 91 cents land and 86750 sqft building .This building has multiplex 4 screen ,jio mart, easybuy bbq, hot chix and other food restaurant and many more. This property is generating good rental income and is a source of income for investors`,
    },
    {
      id: 21,
      name: "Uppinkote,Bramavara",
      category: "Land",
      isActive: true,
      imagess: [
        " https://lh3.googleusercontent.com/d/1cUoIGrPauzLtsfre8g4aHmfUcxJZwKgL=w1000",
        " https://lh3.googleusercontent.com/d/1ANllISSp2XlVrWIwhcCnJk-uuUE02TCG=w1000",
        " https://lh3.googleusercontent.com/d/1HSewtvKlkkCBJ9IxYhBkVnbSy_sE-vfj=w1000",
        " https://lh3.googleusercontent.com/d/13Oh2iWwC0pM_6aDS0AJeP0wUtySP9FwG=w1000",
        " https://lh3.googleusercontent.com/d/1H7bKjYrJhtB4mHdObKy_OB2ZN_lJ5AMD=w1000",
      ],
      property: "21) 58 cents river touch property ",
      price: "85 lakhs",
      description: `58 cents land along with a new terrace house plus one additional door number is available near uppinkote, bramavara. This property is 1 km from bramavar highway. Its a river touch property with good view suitable for resorts and farmhouses and also for water activities. `,
    },
    {
      id: 22,
      name: "Adapady, palli",
      category: "Land",
      isActive: true,
      imagess: [
        " https://lh3.googleusercontent.com/d/19sJfmDoBU4HQYK6NOEjHy2ZhjFGWQ3QT=w1000",
        " https://lh3.googleusercontent.com/d/10Q8mJZ0B6mFDhmbmcrGmtx7R_kDxPtlC=w1000",
        " https://lh3.googleusercontent.com/d/1so3xfs7-MfjYkZkJCbvnYxFAjpuV683X=w1000",
        " https://lh3.googleusercontent.com/d/1LK3mzPN6gBo0i0u33wX3gwXYPuQY-CeY=w1000",
        " https://lh3.googleusercontent.com/d/14fIg4GOxryTqqEy2ncAvTd4KjsLivCoD=w1000",
      ],
      property: "22) 1.93 acre agricultural property ",
      price: "50,000 per cents",
      description: `1.93 land agriculture near adapady,ninjoor. This property is 1 km from state highway. This is a plain land with few cashew trees and mango trees it has good water source and is suitable for resorts and farmhouse . `,
    },
    {
      id: 23,
      name: "kallianpura",
      category: "Beach",
      isActive: true,
      imagess: [
        " https://lh3.googleusercontent.com/d/1kKnIthueoz-82n4ajLxks7QZgoUX56JG=w1000",
        " https://lh3.googleusercontent.com/d/1a231711tFMCeGEDiygd8wM_I1hFjiA34=w1000",
        " https://lh3.googleusercontent.com/d/1rWU2-7WCgHxQATs2PQ5rOPvoBJ6Qprh7=w1000",
        " https://lh3.googleusercontent.com/d/1Lq4YkviuBGrn_TSF10-s1eHNeTUXQnHT=w1000",
        " https://lh3.googleusercontent.com/d/1MYuEHVnvVKbWLFHAQ3BojOp5Eu3GimlV=w1000",
        " https://lh3.googleusercontent.com/d/1tF8GX6INzkoS2Yu3pdC6fmwRP0DrWIyY=w1000",
      ],
      property: "23) 87 cent river side property ",
      price: "2,30,000 per cents",
      description: `87 cents river side property. This property has 1 door number . It is highly suitable for resorts and farmhouses and also for water activities. `,
    },
    {
      id: 24,
      name: "mudarangady",
      category: "Land",
      isActive: true,
      imagess: [
        " https://lh3.googleusercontent.com/d/1ACWgv0NmtVIlAVAUt-PNsXFB8o9dfrTZ=w1000",
        " https://lh3.googleusercontent.com/d/1CJ4J8oEP2UCwYaG9c6t0MPbJzpzq_eiG=w1000",
        " https://lh3.googleusercontent.com/d/1oP41YAk8xO5EG-dHhvgJgmPlS4et0wDp=w1000",
        " https://lh3.googleusercontent.com/d/1CBrR-fH-Qj6wa2AkadNkZdaT7lWKSSy7=w1000",
        " https://lh3.googleusercontent.com/d/1tmtoVCCP14vHbaZmfiZLhYuC2hA5YhxX=w1000",
      ],
      property: "24) 49 cents land along with 1 tiled house ",
      price: "65 lakhs",
      description: `49 cents land near mudarangady. This property has 1 tiled house. Its a main road touch property. This is is opposite to BPCL petrol pump and has a open well with good water source. Tiled house is in good condition for more details contact us in given number below. `,
    },
    {
      id: 25,
      name: "Hejamadi",
      category: "Land",
      isActive: true,
      imagess: [
        " https://lh3.googleusercontent.com/d/1DsRngGcpHYD4oFslgBt2rQsvpWOemg84=w1000",
        " https://lh3.googleusercontent.com/d/1W1pYLK8FAdMBz3APT4p4Nwfhnheo71X4=w1000",
        " https://lh3.googleusercontent.com/d/1J6KL2XWFRHoGYW5zb1pvAqqXPCJs2arI=w1000",
        " https://lh3.googleusercontent.com/d/1cxgH2k1Vifg_eho8Wg-HWhLlQDbMv93w=w1000",
      ],
      property: "25) 7 acre land with 1 tiled house ",
      price: "70 lakhs per acre",
      description: `7 acre River touch land . This property has 1 tiled house. This property 3 km far from hejamadi toll. It has over 1500 coconut trees and is suitable for resorts ,farmhouses and water sports activities for more details contact us in given number below. `,
    },
    {
      id: 26,
      name: "chikkabettu,hirgan",
      category: "Land",
      isActive: true,
      imagess: [
        " https://lh3.googleusercontent.com/d/1gtyvwg50cXYii0xt4NW1IHtssl0vL2yy=w1000",
        " https://lh3.googleusercontent.com/d/1j8ISLALtTKPtMNQpKuVAQOa_qJXa3h8n=w1000",
        " https://lh3.googleusercontent.com/d/1qRmTD7hPpcP6K9eCsc8h1zM-XvZJ891f=w1000",
        " https://lh3.googleusercontent.com/d/1-kz0Fm52cvzXz7tDjs1aMljorMlSYSBZ=w1000",
        " https://lh3.googleusercontent.com/d/1Gsxop1CLKUGrRNNgLdc8HsKUKNMV6Ame=w1000",
      ],
      property: "26) 4 Acre land",
      price: "15,000 per cents",
      description: `4 Acre land agriculture near chikkabettu,hirgan . This property is 500 m from state highway. This is a plain land with one pond and few fields its highly suitable for agriculture purposes . `,
    },
    {
      id: 27,
      name: "Hirgan",
      category: "Land",
      isActive: true,
      imagess: [
        " https://lh3.googleusercontent.com/d/1pYKJAIINDzYTIhb33rw4i66Vvomylnjy=w1000",
        " https://lh3.googleusercontent.com/d/1QZxSsRhaOnXtHHqY7g8A1bGfUNEwWNP4=w1000",
        " https://lh3.googleusercontent.com/d/11VW9wbF9Yl--q_60gRm9QwMmlJZrb0Gr=w1000",
        " https://lh3.googleusercontent.com/d/11iBts-qC74-yCfZcr-rOMhticiCaiVt3=w1000",
        " https://lh3.googleusercontent.com/d/1180DsVq8-uVRX9T-JsEKWEwRcFC6thZi=w1000",
      ],
      property: "27) 88 cents land",
      price: "80,000 per cents",
      description: `88 land agriculture near hirgan . This property is 200 m from state highway. Its very close to government school and government hospital . Its highly suitable for layouts . `,
    },
    {
      id: 28,
      name: "Hermunde,ajekar",
      category: "Land",
      isActive: true,
      imagess: [
        " https://lh3.googleusercontent.com/d/1NBqsuLDdHxLpU4LrYlJ3ktQc5lxMcZha=w1000",
        " https://lh3.googleusercontent.com/d/19bbNejbkmF64g7ZY4g8cnqJIKAC7FDyk=w1000",
        " https://lh3.googleusercontent.com/d/1booteB1IAnIhhfvJhy3nK1KTSxUZNbJ1=w1000",
        " https://lh3.googleusercontent.com/d/1C1ar9V0OcM2ooIu-esUWVND7-TEG3m_j=w1000",
        " https://lh3.googleusercontent.com/d/12wj9sklYPja2VuhHzq4TwDHZHNbf2IAu=w1000",
        " https://lh3.googleusercontent.com/d/1g-slKogyiDui1xyfNhk1uuEECxBXAlgL=w1000",
        " https://lh3.googleusercontent.com/d/19bbNejbkmF64g7ZY4g8cnqJIKAC7FDyk=w1000",
      ],
      property: "28) 18 acre land",
      price: "15,000 per cents",
      description: `18 land agriculture near hermunde ajekar . This property is 3km from state highway. It has 1 house and 3 well Thousands of arecanut and rubber tree and also many other fruits tree .This property is generating high agricultural income . `,
    },
    {
      id: 29,
      name: "Hermunde,ajekar",
      category: "Land",
      isActive: true,
      imagess: [
        " https://lh3.googleusercontent.com/d/1c1AYD0oR0-5U9QOJ6kf3ORBY9hRQxg0p=w1000",
        " https://lh3.googleusercontent.com/d/1joUnL7k5hAZyLqBBxUW8UYOKL0vQB1EK=w1000",
        " https://lh3.googleusercontent.com/d/1GOd9_6DTm3fiZJ5WZvkiQFbqHlHta36X=w1000",
        " https://lh3.googleusercontent.com/d/17thDfIN26tNfXSXtKRx7DPlNZv0FkbYx=w1000",
        " https://lh3.googleusercontent.com/d/1z9a0oyY476XcX5L5BIrNB_d4dyiaj1Xz=w1000",
        " https://lh3.googleusercontent.com/d/1LKG9V6RwIbNIJQW4bO9EHR45nxELoquz=w1000",
        " https://lh3.googleusercontent.com/d/1cVRfeVEE0PreCcKnr77zP6m_J5qzFYoN=w1000",
      ],
      property: "29) 18 acre land",
      price: "13,000 per cents",
      description: `18 land agriculture near hermunde ajekar .It has additional 3 acre kumki. This property is 3km from state highway. It has 1 house and  11 acres  of  rubber tree and remaining 7 acre is empty land .This property is generating high agricultural income . `,
    },
    {
      id: 30,
      name: "Kinnimulky",
      category: "Flats",
      isActive: true,
      imagess: [
        " https://lh3.googleusercontent.com/d/174pG3jzjLgPAXEddMWGDkLZQS-13AhbI=w1000",
        " https://lh3.googleusercontent.com/d/1wOe7yelkONMu7g-NffyB0NkrfVgA5An4=w1000",
        " https://lh3.googleusercontent.com/d/16pMKFMNZ2Rx1UaiPOQxgCFlkY05Y-WWR=w1000",
        " https://lh3.googleusercontent.com/d/1QngMVLqtQAZB8aXJh8sFksSvOcqCfRCE=w1000",
        " https://lh3.googleusercontent.com/d/1qZxk_POxaBrVzY8YlziWezsru3SIlTdj=w1000",
        " https://lh3.googleusercontent.com/d/1EaebhsH02clRkbbStGQhdWIAlD4aUiNP=w1000",
        " https://lh3.googleusercontent.com/d/1fSyWBccLncxvTp69u8BVkjQ_CQcOB6wy=w1000",
      ],
      property: "29) 3 BHK flat",
      price: "1,20,00,000",
      description: `3 BHk flat for sale in kinnimulky. flat is of 1650 sqft and is in 5th floor, it has 2 car parking for more details call or whatsapp us in below number . `,
    },
  ];

  return { Productobj, ProductobjTop10 };
}
// Land,Beach,House,Commercial,apartments,apartments,Flats
export function ProvideDashboard({ children }) {
  const auth = useDashboardProvider();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}
